import React, {Component} from 'react';
import style from './Contacts.module.scss';
import ContactsContent from "./ContactsContent/ContactsContent";


class Contacts extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }
    
    animateBlock() {
        this.wrapper.current.classList.add(style.animateClass)
    }
    
    componentDidMount() {
        if(this.props.animate) {
            setTimeout(() => {
                this.animateBlock()
            }, 300)
        }
    }
    
    componentDidUpdate() {
        if(this.props.animate) {
            this.animateBlock()
        }
    }
    
    render() {
        const {animate} = this.props,
              contactsData = this.props.block;
    
        let back = '';
        if(this.props.widthPage >= 768 || window.innerWidth >= 768){
            back = contactsData.image
        } else {
            back = contactsData.imageForMobile !== '' ? contactsData.imageForMobile : contactsData.image
        }
        
        return (
            <section className={`container ${style.contacts}`} style={{"backgroundImage": `url(${back})`}} ref={this.wrapper}>
                <div className={style.contacts__container}>
                    <h1 className={style.contacts__headline}>{contactsData.title}</h1>
                    
                    <ContactsContent animate={animate} contactsData={contactsData} />
                </div>
            </section>
        );
    }
}

export default Contacts;