import React, {Component} from "react";


import classes from "./BrandSlider.module.scss";
import "./BrandSlider.module.scss";
import Slider from "react-slick";

function SampleNextArrow(props) {
	const {onClick} = props;
	return (
		<div
			className={`${classes.BrandsArrows} ${classes.BrandsArrowsRight}`}
			onClick={onClick}
		>
			<i className="ic-long-arrow-right"></i>
		</div>
	);
}

function SamplePrevArrow(props) {
	const {onClick} = props;
	return (
		<div
			className={`${classes.BrandsArrows} ${classes.BrandsArrowsLeft}`}
			onClick={onClick}
		>
			<i className="ic-long-arrow-left"></i>
		</div>
	);
}

class BrandSlider extends Component {
	
	render() {
		const settings = {
			slidesToShow   : 1,
			slidesToScroll : 1,
			speed          : 1000,
			dots           : false,
			autoplay: false,
			arrows: true,
			adaptiveHeight: true,
			nextArrow      : <SampleNextArrow/>,
			prevArrow      : <SamplePrevArrow/>,
		};
		let images  = this.props.items;
		
		return (
			<Slider className={classes.BrandsSliderWrapper} ref={c => (this.slider = c)} {...settings}>
				{
					images.map((item, index) => {
						return (
							<div className={classes.item} key={index}>
								<img src={item.image} alt=""/>
							</div>
						)
						
					})
				}
			</Slider>
		)
	}
}

export default BrandSlider