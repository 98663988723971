import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import App from "./App";

//const App = lazy(() => import('./App'));

window.$GLOBAL = {};
let pathArray = window.location.pathname.split('/');

if(pathArray.length >= 2 && pathArray[1].length === 2){
	window.$LNG =  `/${pathArray[1]}/`;
}else{
	window.$LNG = '/'
}

let pageDomain = window.location.origin;

if(pageDomain.match(/localhost/)){
	pageDomain = 'https://apk-invest.com.ua';
}


fetch(`${pageDomain}${window.$LNG}core/ajax/index`)
	.then(res => res.json())
	.then(
		(result) => {
			window.$GLOBAL = result;
			console.log( window.$GLOBAL)
			window.addEventListener('resize', () => {
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty('--vh', `${vh}px`);
			});
			
			ReactDOM.render(
				<BrowserRouter>
					<App/>
				</BrowserRouter>,
				document.getElementById('root')
			);
		},
		// Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
		// чтобы не перехватывать исключения из ошибок в самих компонентах.
		(error) => {
		
		}
	)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
