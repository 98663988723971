import React, {Component} from "react";
import classes from "./Gallery.module.scss"
import BrandVideo from "../BrandVideo/BrandVideo";
import BrandSlider from "../BrandSlider/BrandSlider";



class Gallery extends Component {
    
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }
    
    animateBlock () {
        this.wrapper.current.classList.add(classes.finished);
    }
    
    componentDidMount() {
        if (this.props.brandAnimate) {
            setTimeout(()=>{
                this.animateBlock()
            },300)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.brandAnimate === true) {
            this.animateBlock()
        }
    }

    render (){
        return(
            <div className={classes.brandSliderWrapper} ref={this.wrapper}>
                {this.props.video.length ?
                    <BrandVideo mp4={this.props.video.videoMp4} ogv={this.props.video.videoOgv} webm={this.props.video.videoWebm}/>
                    :
                    <BrandSlider items={this.props.images} />
                }
            </div>
        )
    }
}

export default Gallery;