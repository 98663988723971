import React, {Component} from "react";
import classes from "./BrandsContent.module.scss"
import CustomScroll from 'react-custom-scroll';
import "react-custom-scroll/dist/reactCustomScroll.js"
import "react-custom-scroll/dist/customScroll.css"
import "../../../customScroll.scss"


class BrandsContent extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }
    
    animateBlock () {
        this.wrapper.current.classList.add(classes.finished);
    }
    
    componentDidMount() {
        if (this.props.brandAnimate) {
            setTimeout(()=>{
                this.animateBlock()
            },300)
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.brandAnimate === true) {
            this.animateBlock();
        }
    }

    render() {
        return (
           
                <div className={classes.brandsContentWrapper} ref={this.wrapper}>
                    <CustomScroll heightRelativeToParent="100%">
                        <div dangerouslySetInnerHTML={{__html: this.props.content}} className={`text-formatted ${classes.brandsContentInner}`}></div>
                    </CustomScroll>
                </div>
           
        )
    }
}

export default BrandsContent;