import React, {Component} from "react";
import style from './Ecology.module.scss';
import CustomScroll from "react-custom-scroll";
import BrandVideo from "../Widgets/BrandVideo/BrandVideo";
import BrandSlider from "../Widgets/BrandSlider/BrandSlider";


export default class Ecology extends Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
	}
	
	
	animateBlock() {
		this.wrapper.current.classList.add(style.animateClass)
	}
	
	componentDidMount() {
		if(this.props.animate) {
			setTimeout(() => {
				this.animateBlock()
			}, 300)
		}
	}
	
	componentDidUpdate() {
		if(this.props.animate) {
			this.animateBlock()
		}
	}
	
	render() {
		const EcologyContent = this.props.block;
		
		let back = '';
		if(this.props.widthPage >= 768 || window.innerWidth >= 768){
			back = EcologyContent.image
		} else {
			back = EcologyContent.imageForMobile !== '' ? EcologyContent.imageForMobile : EcologyContent.image
		}
		
		return (
			<section className={`container ${style.Ecology}`} style={{"backgroundImage" : `url(${back})`}} ref={this.wrapper}>
				<div className={style.EcologyContent}>
					<div className={style.EcologyHeadline}>{EcologyContent.title}</div>
					<div className={style.EcologyContentWrapper}>
						<div className={style.ContentTitle}>{EcologyContent.titleBlock}</div>
						<div className={style.contentInner}>
							<div className={style.EcologyTextWrapper}>
								<CustomScroll heightRelativeToParent="100%">
									<div className={`${style.EcologyText} text-formatted`}
									     dangerouslySetInnerHTML={{__html : EcologyContent.content}}></div>
								</CustomScroll>
							</div>
							<div className={style.EcologyPhotoWrapper}>
								{
									EcologyContent.videos.desktop.file_mp4 !== '' ?
										<BrandVideo mp4={EcologyContent.videos.desktop.file_mp4}
										            ogv={EcologyContent.videos.desktop.file_ogv}
										            webm={EcologyContent.videos.desktop.file_webm}
											//							            poster={infoItem.videos.desktop.poster}
										/>
										:
										<BrandSlider items={EcologyContent.gallery}/>
								}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}