import React from "react";
import classes from "./MenuToggle.module.scss"

const MenuToggle = props => {
	const cls= [
		classes.MenuToggle,
	];
	if(props.isOpenMenu){
		cls.push(classes.MenuToggle);
		cls.push(classes.open)
	}
	return(
		<div className={cls.join(' ')}
			onClick={props.onToggleMenu}>
			<div className={classes.MenuToggleInner}></div>
			<div className={classes.MenuToggleInner}></div>
			<div className={classes.MenuToggleInner}></div>
		</div>
	)
};

export default MenuToggle