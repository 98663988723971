import React, {Component} from 'react';
import style from './ContactsContent.module.scss';
import ContactForms from "../ContactsForm/ContactForm_test";

class ContactsContent extends Component {
	
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
	}
	
	
	componentDidUpdate() {
		if(this.props.animate) {
			this.wrapper.current.classList.add(style.animateClass)
		}
	}
	
	render() {
		const {contactsData} = this.props;
		const numbersList    = () => {
			const numbers = () => {
				return (
					contactsData.phones.map((elem, index) => {
						return (
							<a href={`tel:${elem.replace(/\s|\(|\)/gi, '')}`}
							   className={style.contacts__infoNumber}
							   key={`content_${index}`}>
								<i className="ic-cellphone-filled"></i>
								<span className={style.contacts__infoNumberText}>{elem}</span>
							</a>
						)
					})
				)
			}
			
			return (
				<div className={style.contacts__infoPhone}>
					<h3 className={style.contacts__infoElemHealine}>{contactsData.phoneBlockTitle}</h3>
					{numbers()}
				</div>
			)
		}
		
		const mailList = () => {
			const mails = () => {
				return (
					<a href={`mailto:${contactsData.email}`} className={style.contacts__infoMail}>
						<i className="ic-email-3"></i>
						<span className={style.contacts__infoMailText}>{contactsData.email}</span>
					</a>
				)
			}
			
			return (
				<div className={style.contacts__infoMailList}>
					<h3 className={style.contacts__infoElemHealine}>{contactsData.mailBlockTitle}</h3>
					{mails()}
				</div>
			)
		}
		
		const addressList = () => {
			const address = () => {
				return (
					<div className={style.contacts__infoAddress}>
						<i className="ic-location-1"></i>
						<span className={style.contacts__infoAddressText}>{contactsData.address}</span>
					</div>
				)
			}
			
			return (
				<div className={style.contacts__infoAddressList}>
					{address()}
				</div>
			)
		}
		
		const socialList = () => {
			const social = () => {
				return (
					contactsData.socialNetworks.map((elem, index) => {
						return (
							
							<a href={elem.url}
							   className={style.contacts__infoSocialLink}
							   target="_blank"
							   rel="noopener noreferrer"
                               key={`social_${index}`}
                            >
								<img className={style.contacts__infoSocialIcon} src={`${elem.image}`} alt={elem.name}/>
							</a>
						)
					})
				)
			}
			
			return (
				<div className={style.contacts__infoSocialList}>
					<h3 className={style.contacts__infoSocialHealine}>{contactsData.socialBlockTitle}</h3>
					
					{social()}
				</div>
			)
		}
		
		return (
			<div className={style.contacts__content} ref={this.wrapper}>
				<div className={style.contacts__info}>
					<h2 className={style.contacts__infoHeadline}>{contactsData.company}</h2>
					
					{contactsData.phones.length > 0 ? numbersList() : ''}
					
					{contactsData.email !== '' ? mailList() : ''}
					
					{contactsData.address !== '' ? addressList() : ''}
					
					{contactsData.socialNetworks.length > 0 ? socialList() : ''}
				</div>
				<div className={style.contacts__form}>
					<h3 className={style.contacts__formHeadline}>{window.$GLOBAL.translation.formTitle}</h3>
					
					<ContactForms/>
				</div>
			</div>
		);
	}
}

export default ContactsContent;