import React from "react";
import classes from "./MainMenu.module.scss"

import MenuList from "./MenuList/MenuList";
import CustomScroll from "react-custom-scroll";

function MainMenu(props) {
	const menuBlock     = window.$GLOBAL.mainMenu.items_tree[0];
	let menuLink        = window.$GLOBAL.pagesMenu.items_tree[0];
	const contactsBlock = window.$GLOBAL.contactInformation;
	const socialNetwork = window.$GLOBAL.socialInformation;
	const menuBG =  window.$GLOBAL.mainMenu.image;

  menuLink = menuLink.map(link => ({
		...link,
		menuSecondLvl: window.$GLOBAL.pagesMenu.items_tree[link.id] || [],
	}))

	return (
		<div className={`${classes.MainMenu} ${props.menuOpen ? classes.active : ''}`}
		     style={{"backgroundImage" : `url(${menuBG})`}}>
			<div role="button" className={classes.closeMenu} onClick={props.toggleMenuHandler}>
				<i className="ic-close"></i>
			</div>
			<CustomScroll heightRelativeToParent="100%">
				<MenuList menuBlock={menuBlock} onToogleMenuLink={props.toggleMenuHandler} innerPages={true} pageWidth={props.pageWidth}/>
				<MenuList menuBlock={menuLink} onToogleMenuLink={props.toggleMenuHandler} pageWidth={props.pageWidth} />
				<div className={classes.contactsBlock}>
					<div className={classes.title}>{contactsBlock.titleBlock}</div>
					<a href={`tel:${contactsBlock.phone.replace(/\s|\(|\)/gi, '')}`} className={classes.link}>
						<i className="ic-cellphone-filled"></i>
						<span>{contactsBlock.phone}</span>
					</a>
					<a href={`mailto:${contactsBlock.email}`} className={classes.link}>
						<i className="ic-email-3"></i>
						<span>{contactsBlock.email}</span>
					</a>
				</div>
				<div className={classes.socials}>
					<div className={classes.socialsTitle}>{socialNetwork.titleBlock}</div>
					{
						socialNetwork.socialNetworks.map((item, index) => {
							return (
								<a href={item.url} target="_blank" rel="noreferrer noopener" className={classes.socialsLinks} key={`menu_${index}`}>
									<img className={classes.socialImg} src={item.image} alt={item.name}/>
								</a>
							)
						})
					}
					
				</div>
			</CustomScroll>
		</div>
	)
}

export default MainMenu