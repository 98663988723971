import React, {Component} from "react";
import classes from "./CertificateSlider.module.scss";
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function SampleNextArrow(props) {
	const {onClick} = props;
	return (
		<div
			className={classes.nextButton}
			onClick={onClick}
		>
			<i className="ic-arrow-right-light"></i>
		</div>
	);
}

function SamplePrevArrow(props) {
	const {onClick} = props;
	return (
		<div
			className={classes.prevButton}
			onClick={onClick}
		>
			<i className="ic-arrow-left-light"></i>
		</div>
	);
}

class CertificateSlider extends Component {
	constructor(props) {
		super(props);
		const galleryImages = [];
		this.props.items.children.forEach((item,index)=>{
			galleryImages.push(item.image)
		});
		this.state = {
			nav1 : null,
			images: galleryImages,
			current : 0,
			isOpen: false,
		};
	}
	
	handleClickImage = (e, index) => {
		this.setState({
			current: index,
			isOpen: true
		})
	}
	
	
	componentDidMount() {
		this.setState({
			nav1 : this.slider1,
		});
	}
	
	componentDidUpdate() {
		this.slider1.slickGoTo(this.props.currentItem);
	}
	
	render() {
		const settings = {
			slidesToShow   : 1,
			slidesToScroll : 1,
			speed          : 500,
			dots           : false,
			adaptiveHeight : true,
			autoplay       : false,
			nextArrow      : <SampleNextArrow/>,
			prevArrow      : <SamplePrevArrow/>,
			swipe          : false,
			beforeChange   : (oldIndex, newIndex) => {
				this.props.handleChangeItem(newIndex)
			},
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						variableWidth: true,
					}
				},
				{
					breakpoint: 767,
					settings: {
						variableWidth: false,
					}
				},
			]
		};
		
		let utpItems    = this.props.items;
		
		
		return (
			<div className={classes.slidersWrapper}>
				<Slider ref={slider => (this.slider1 = slider)} asNavFor={this.state.nav1} {...settings} >
					{
						utpItems.children.map((item, index) => {
       
							return (
								<img src={item.image} alt="" key={`slick_${index}`} onClick={ e => this.handleClickImage(e, index)}/>
							)
						})
					}
				</Slider>
				<div className={classes.navSlider}>
					<div className={classes.navSliderCounter}>
						<div>
							<span className={classes.navSliderCount}>{this.props.currentItem + 1}</span>
							<span className={classes.navSliderCountAll}>{`/${utpItems.children.length}`}</span>
						</div>
					</div>
				</div>
				{this.state.isOpen &&
				<Lightbox
					mainSrc={this.state.images[this.state.current]}
					nextSrc={this.state.images[(this.state.current + 1) % this.state.images.length]}
					prevSrc={this.state.images[(this.state.current + this.state.images.length - 1) % this.state.images.length]}
					onCloseRequest={() => this.setState({ isOpen: false })}
					onMovePrevRequest={() =>
						this.setState({
							current: (this.state.current + this.state.images.length - 1) % this.state.images.length,
						})
					}
					onMoveNextRequest={() =>
						this.setState({
							current: (this.state.current + 1) % this.state.images.length,
						})
					}
				/>
				}
			</div>
		)
	}
}

export default CertificateSlider;