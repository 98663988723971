import React from 'react';
import style from "./HistoryTabItem.module.scss";

const HistoryTabItem = ({element, index, selectItem, handleChangeItem}) => {
    return (
        <div onClick={() => handleChangeItem(index)} key={element.key} className={selectItem === index ? style.HistoryItemActive : null}>
            <div className={style.HistoryItem}>
                <div className={style.HistoryYear}>
                    <span className={style.HistoryYearText}>{element.year}</span>
                </div>
            </div>
        </div>
    );
};

export default HistoryTabItem;