import React, {Component} from 'react';
import style from './Issuer.module.scss';
import IssuerContent from "./IssuerContent/IssuerContent";


class Issuer extends Component{
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
       
    }
    componentDidMount() {
        this.wrapper.current.classList.add(style.active);
    }
   
    
    render() {
    
        let back = '';
        if(this.props.widthPage >= 768 || window.innerWidth >= 768){
            back = this.props.block.image
        }else{
            back = this.props.block.imageForMobile !== '' ? this.props.block.imageForMobile : this.props.block.image
        }
       return (
           <section className={`container ${style.issuer}`} style={{"backgroundImage": `url(${back})`}} ref={this.wrapper}>
               <h1 className={style.issuer__headline}>{this.props.block.title}</h1>
            
               <IssuerContent issuerData={this.props.block} animate={this.props.animate}/>
           </section>
       );
   }
}

export default Issuer;