import React from "react";
import Brands from "./component/Brands/Brands";
import Career from "./component/Career/Career";
import Certificate from "./component/Certificate/Certificate";
import CompanyStructure from "./component/CompanyStructure/CompanyStructure";
import Contacts from "./component/Contacts/Contacts";
import Ecology from "./component/Ecology/Ecology";
import HistoryPage from "./component/History/HistoryPage";
import HomeScreen from "./component/HomeScreen/HomeScreen";
import Issuer from "./component/Issuer/Issuer";
import Partners from "./component/Partners/Partners";
import StructureInfo from "./component/StructureInfo/StructureInfo";
import Team from "./component/Team/Team";
import Tour from "./component/Tour/Tour";

const DefaultTemplate = ({ block }) => (
	<div className="default-template-wrap">
		<div className="bg-color"></div>
		<div className="default-template">
			<div className="content">
				<span dangerouslySetInnerHTML={{ __html: block.content }}></span>
			</div>
		</div>
	</div>
)


const Components = {
	HomeScreen: HomeScreen,
	CompanyStructure: CompanyStructure,
	StructureInfo: StructureInfo,
	History : HistoryPage,
	Brands: Brands,
	InnovationAndCertification: Certificate,
	Contacts: Contacts,
	Ecology: Ecology,
	IssuerInformation: Issuer,
	Documents: Issuer,
	Partners: Partners,
	Tour: Tour,
	Career: Career,
	Team: Team
};

export default block => {
	if (typeof Components[block.template] !== "undefined") {
		return React.createElement(Components[block.template], {
			key: block.id,
			block: block,
			animate: block.animate,
			handleChangeSlideStructure: block.handleChangeSlideStructure,
			currentPage: block.currentPage,
			handleChangeSlide : block.handleChangeSlide,
			changeStructureItem: block.changeStructureItem,
			structureActive: block.structureActive,
			widthPage: block.widthPage
		});
		
	}
	return React.createElement(
		DefaultTemplate,
		{ key: block._uid, block }
	);
};