import React from "react";

const Input = props => {

	let groupActive = 'form-group';
	if(props.value !== ''){
		groupActive = 'form-group _focus';
	}
	let required = props.requiredicon === "true" ? "required" : null;
	return (
		<div className={groupActive}>
			<label htmlFor={`${props.name}_${props.id}`} className="form-label">
				{props.title}
			</label>
			<div className={`controls ${props.errortext ? '_error' : ''}`}>
				<div className={props.iconname + " input-icon " + required}></div>
				<input
					className={`form-control`}
					id={`${props.name}_${props.id}`}
					name={props.name}
					type={props.inputtype}
					value={props.value}
					onChange={props.handleChange}
					placeholder={props.placeholder}
					autoComplete="off"
					{...props}
				/>
				
			</div>
			<div className="errors">
				{props.errorText}
			</div>
		</div>
	);
};

export default Input;
