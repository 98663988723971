import React, {Component} from 'react';
import ReactDOM from "react-dom";
import style from './HistoryYearsList.module.scss';
import './HistoryContentTransition.scss';
import './HistorySliderTransition.scss';
import HistorySlider from "../HistorySlider/HistorySlider";
import HistoryTabItem from "../HistoryTabItem/HistoryTabItem";
import HistoryContent from "../HistoryContent/HistoryContent";
import {
	CSSTransition,
	SwitchTransition
} from "react-transition-group";


class HistoryYearsList extends Component {
	constructor(props) {
		super(props);
		this.historyList = React.createRef();
		this.state       = {
			selectItem : 0,
		}
		
		this.handleScrollListTop = this.handleScrollListTop.bind(this);
		this.handleScrollListBottom = this.handleScrollListBottom.bind(this);
	}
	
	handleChangeItem = (index) => {
		this.setState({
			selectItem : index,
		})
	}
	
	handleScrollListTop = () =>{
		this.historyList.current.scrollTo({
			top      : 0,
			behavior : "smooth"
		});
    }
    
    handleScrollListBottom = () =>{
	    this.historyList.current.scrollTo({
		    top      : this.historyList.current.scrollHeight,
		    behavior : "smooth"
	    });
    }
    
	list = () => {
		return this.props.yearList.map((elem, index) => {
			return (
				<HistoryTabItem handleChangeItem={this.handleChangeItem}
				                element={elem}
				                index={index}
				                selectItem={this.state.selectItem}
				                key={`hisTabItem_${elem.id}`}/>
			)
		})
	}
	
	content = () => {
		return (
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={this.state.selectItem}
					addEndListener={(node, done) => {
						node.addEventListener("transitionend", done, false);
					}}
					classNames="HistoryContentTransition"
				>
					
					<HistoryContent animate={this.props.animate}
					                yearList={this.props.yearList}
					                selectItem={this.state.selectItem}/>
				
				</CSSTransition>
			</SwitchTransition>
		)
	}
	
	slider = () => {
		return (
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={this.state.selectItem}
					addEndListener={(node, done) => {
						node.addEventListener("transitionend", done, false);
					}}
					classNames="HistorySliderTransition"
				>
					<div className={style.HistorySliderContainer}>
						<HistorySlider animate={this.props.animate}
						               items={this.props.yearList[this.state.selectItem].gallery}/>
					</div>
				</CSSTransition>
			</SwitchTransition>
		
		)
	}
	
	componentDidUpdate() {
		if(this.props.animate) {
			ReactDOM.findDOMNode(this).classList.add(style.animateClass)
		}
	}
	
	render() {
		let yearsArrow = ''
		if(this.props.yearList.length > 7) {
			yearsArrow = <div>
				<div className={style.arrowTop} onClick={this.handleScrollListTop}>
					<i className="ic-arrow-up-light"></i>
				</div>
				<div className={style.arrowBottom} onClick={this.handleScrollListBottom}>
					<i className="ic-arrow-down-light"></i>
				</div>
			</div>
		}
		return (
			<div className={style.HistoryContentContainer}>
				{yearsArrow}
				<div className={style.HistoryList} ref={this.historyList}>
					
					{this.list()}
				</div>
				
				<div className={style.HistoryItemContent}>
					{this.content()}
					{this.slider()}
				</div>
			</div>
		);
	}
}

export default HistoryYearsList;