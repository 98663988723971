import React, {Component} from "react";
import style from './Team.module.scss';
import CustomScroll from "react-custom-scroll";
import TeamItem from "./TeamItem/TeamItem";


export default class Team extends Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef()
	}
	
	
	animateBlock() {
		this.wrapper.current.classList.add(style.animateClass)
	}
	
	componentDidMount() {
		if(this.props.animate) {
			this.timer = setTimeout(() => {
				this.animateBlock()
			}, 300)
		}
	}
	
	componentDidUpdate() {
		if(this.props.animate) {
			this.animateBlock()
		}
	}
	
	componentWillUnmount() {
		clearTimeout(this.timer)
	}
	
	
	render() {
		const block       = this.props.block;
		const TeamContent = block;
		const team        = block.employees.default;
		const headerTeam  = block.employees.leadership;
		
		let back = '';
		if(this.props.widthPage >= 768 || window.innerWidth >= 768){
			back = block.image
		} else {
			back = block.imageForMobile !== '' ? block.imageForMobile : block.image
		}
		
		const teamListHeader = () => {
			return headerTeam.map((elem, index) => {
				return (
					<TeamItem elem={elem} index={`team_item${index}`} block={block} key={`headerTem_${index}`}/>
				)
			})
		}
		
		const teamList = () => {
			return team.map((elemItem, index) => {
				return (
					<li className={style.TeamListItem} key={`teamList_${index}`}>
						<div className={style.TeamListImageWrapper}>
							<img className={style.TeamListImage} src={elemItem.image} alt={elemItem.fio}/>
						</div>
						<div className={style.TeamListItemText}>
							<div className={style.TeamListItemName}>{elemItem.fio}</div>
							<div className={style.TeamListItemPosition}>{elemItem.employee_position}</div>
							{elemItem.cellphones !== '' &&
							<div className={style.TeamListItemPhone}>
								<div className={style.TeamListItemPhoneText}>{block.blockPhoneTitle}</div>
								<a className={style.TeamListItemNumber}
								   href={`tel:${elemItem.cellphones.replace(/\s|\(|\)/gi, '')}`}>{elemItem.cellphones}</a>
							</div>
							}
						</div>
					</li>
				)
			})
		}
		
		return (
			<section className={`container ${style.Team}`}
			         style={{"backgroundImage" : `url(${back})`}}
			         ref={this.wrapper}>
				<div className={style.TeamContent}>
					<div className={style.TeamHeadline}>{TeamContent.title}</div>
					<div className={style.TeamContainer}>
						<div className={style.TeamContainerHeadline}>{TeamContent.additionalTitle}</div>
						<div className={style.TeamListsContainer}>
							
							<div className={style.TeamListHeaderWrapper}>
								<CustomScroll heightRelativeToParent="100%">
									<ul className={style.TeamListHeader}>
										{teamListHeader()}
									</ul>
								</CustomScroll>
							</div>
							<div className={style.TeamListWrapper}>
								<CustomScroll heightRelativeToParent="100%">
									<ul className={style.TeamList}>
										{teamList()}
									</ul>
								</CustomScroll>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}