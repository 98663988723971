import React, {Component} from 'react';
import {ReactComponent as PreloaderSvg} from "../../../images/perloader/preloaderLogo.svg";
import {ReactComponent as PreloaderSvgUa} from "../../../images/perloader/preloaderLogoUA.svg";
import {ReactComponent as PreloaderSvgEn} from "../../../images/perloader/preloaderLogoEN.svg";
import "./PreloaderIcon.scss"
import classes from './proloader_text.module.scss'
import CountUp from "react-countup";

class PreloaderIcon extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }
    componentDidMount() {
        this.wrapper.current.classList.add('animateClass')
    }

    render() {
        let icon;
        if(window.$GLOBAL.currentLanguage.code === 'ru'){
            icon =   <PreloaderSvg />;
        }else if(window.$GLOBAL.currentLanguage.code === 'ua'){
            icon =   <PreloaderSvgUa />;
        }else if(window.$GLOBAL.currentLanguage.code === 'en'){
            icon =   <PreloaderSvgEn />;
        }
        return (
            <div className={`preloader__svg-container`} ref={this.wrapper}>
               {icon}
                <div className={classes.PreloaderText}>
                    <CountUp start={0} end={100} delay={0} duration={5} separator={" "}/>%
                </div>
            </div>
        );
    }
};

export default PreloaderIcon;