import React, {Component} from "react";
import Bigtitle from "./BrandsBigTitle/BrandsBigTitle";
import Gallery from "../Widgets/Gallery/Gallery";
import classes from "./Brands.module.scss";
import BrandsItems from "../Brands/BrandsItems/BrandsItems";
import ContentItems from "../Brands/BrandsContent/BrandsContent";
import {
	CSSTransition,
	SwitchTransition
} from "react-transition-group";


class Brands extends Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
		this.state   = {
			width        : 0,
			height       : 0,
			currentItems : 0
		};
		
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	
	animateBlock() {
		this.wrapper.current.classList.add(classes.animateClass)
	}
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		if(this.props.animate) {
			setTimeout(() => {
				this.animateBlock()
			}, 300)
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.animate) {
			this.animateBlock()
		}
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	updateWindowDimensions() {
		this.setState({
			width  : window.innerWidth,
			height : window.innerHeight
		});
	}
	
	
	render() {
		const block          = this.props.block,
		      brandsUtpItems = block.children[this.state.currentItems],
		      brandsVideo    = brandsUtpItems.videos.desktop,
		      brandsImages   = brandsUtpItems.gallery;
		
		let back = '';
		if(this.props.widthPage >= 768 || window.innerWidth >= 768) {
			back = brandsUtpItems.image
		} else {
			back = brandsUtpItems.imageForMobile !== '' ? brandsUtpItems.imageForMobile : brandsUtpItems.image
		}
		
		return (
			<section className={`container ${classes.Brand}`}
			         ref={this.wrapper}
			         style={{"backgroundImage" : `url(${back})`}}>
				<div className={classes.pageTitle}>{block.title}</div>
				
				<SwitchTransition mode={'out-in'}>
					<CSSTransition
						key={this.state.currentItems}
						addEndListener={(node, done) => {
							node.addEventListener("transitionend", done, false);
						}}
						classNames="brandItemTransition"
					>
						<div className={classes.BrandInner}>
							<BrandsItems brandAnimate={this.props.animate} items={brandsUtpItems}/>
							<div className={classes.rightBrandsBlock}>
								<Bigtitle brandAnimate={this.props.animate} brandstitle={brandsUtpItems.title}/>
								<div className={classes.contentSliderWrapper}>
									<ContentItems brandAnimate={this.props.animate}
									              content={brandsUtpItems.content}/>
									<Gallery brandAnimate={this.props.animate}
									         video={brandsVideo}
									         images={brandsImages}/>
								</div>
							</div>
							{
								block.children.length > 1 &&
								<div className={classes.brandsMenu}>
									{block.children.map((item, index) => {
										return (
											<div className={`${classes.menuItem} ${index === this.state.currentItems && classes.menuActive}`}
											     onClick={() => {this.setState({currentItems : index})}}
											>
												{item.title}
											</div>
										)
									})}
								</div>
							}
						</div>
					</CSSTransition>
				</SwitchTransition>
				
				
				
			</section>
		)
	}
}

export default Brands;