import React, {Component} from "react";
import style from './Tour.module.scss';
import TourForms from "./TourForm/TourForms";



export default class Tour extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef()
    }
    
    
    animateBlock() {
        this.wrapper.current.classList.add(style.animateClass)
        
    }
    
    componentDidMount() {
        if(this.props.animate) {
            this.timer = setTimeout(() => {
                this.animateBlock()
            }, 300)
        }
    }
    
    componentDidUpdate() {
        if(this.props.animate) {
            this.animateBlock()
        }
    }
    
    render() {
        const tourText = this.props.block;
    
        let back = '';
        if(this.props.widthPage >= 768 || window.innerWidth >= 768){
            back = tourText.image
        }else{
            back = tourText.imageForMobile !== '' ? tourText.imageForMobile : tourText.image
        }
        
        return(
            <section className={`container ${style.Tour}`} style={{"backgroundImage": `url(${back})`}} ref={this.wrapper}>
                <div className={style.TourContent}>
                    <div className={style.TourFormWrapper}>
                        <div className={style.TourHeadline}>{tourText.titleBlock}</div>
                        <div className={style.TourText}>{tourText.contentBlock}</div>
                        <TourForms tourText={tourText}/>
                    </div>
                </div>
            </section>
        );
    }
}