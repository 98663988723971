import React, {Component} from "react";
import classes from "./BrandsItems.module.scss"

class BrandsItems extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef()
        this.state = {
            animateTime: Number.parseInt(this.props.animateTime),
        }
    }
    
    animateBlock () {
        this.wrapper.current.classList.add(classes.finished);
    }
    
    componentDidMount() {
        if (this.props.brandAnimate) {
           setTimeout(()=>{
               this.animateBlock()
           },300)
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.brandAnimate) {
            this.animateBlock()
        }
    }

    render() {
        let utpItem = this.props.items;
        const brandsLogo = utpItem.logo;

        return (
            <div className={classes.leftBrandsBlock} ref={this.wrapper}>
                <div className={classes.BrandsLogo}>
                    <img src={brandsLogo} alt=""/>
                </div>
                <div className={classes.UtpBrandWrapper}>
                    <div className={classes.UtpBrandItem}>
                        <div className={classes.UtpBrandTitle}>{utpItem.titleFirstBlock}</div>
                        <div className={classes.UtpBrandValue} dangerouslySetInnerHTML={{__html : utpItem.contentFirstBlock}}></div>
                    </div>
                    <div className={classes.UtpBrandItem} >
                        <div className={classes.UtpBrandTitle}>{utpItem.titleSecondBlock}</div>
                        <div className={classes.UtpBrandValue} dangerouslySetInnerHTML={{__html : utpItem.contentSecondBlock}}></div>
                    </div>
                </div>
                <div className={classes.BtnBrandsWrapper}>
                    <a href={utpItem.link} target="_blank"  rel="noopener noreferrer" className="btn btn-blurry">
                        <span className="btn-text-default">{utpItem.titleLink}</span>
                        <span className="btn-text-hover">{utpItem.titleLink}</span>
                    </a>
                </div>
            </div>

        )
    }
}

export default BrandsItems;