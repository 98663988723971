import React, {Component} from "react";
import "../Widgets/FormElements/Button/Button.scss"
import "./HomePage.scss"
import {
	FullPage,
	Slide
} from 'react-full-page';

import {NavHashLink as NavLink} from 'react-router-hash-link';
import "slick-carousel/slick/slick.css"


import Components from "../../component"


class HomePage extends Component {
	constructor(props) {
		super(props);
		this.wrapper                    = React.createRef();
		this.fullPage                   = React.createRef();
		this.scrollUp                   = React.createRef();
		this.sliders                    = Array.from({length : window.$GLOBAL.pagesOnHome.length}, () => React.createRef());
		let sectionIndex                = Number.parseFloat(this.props.location.hash.replace(/#section-/g, ''))
		this.elemsOffsetTop             = [];
		this.state                      = {
			currentPage         : 0,
			structureItemActive : 0,
			initialSlide        : sectionIndex ? sectionIndex : 0,
			width               : window.innerWidth,
			height              : 0,
			modeScroll          : window.innerWidth >= 1200 ? 'full-page' : 'normal',
			blockOffsets: []
		};
		this.handleChangeSlide          = this.handleChangeSlide.bind(this);
		this.handleChangeSlideStructure = this.handleChangeSlideStructure.bind(this);
		this.changeStructureItem        = this.changeStructureItem.bind(this);
		this.updateWindowDimensions     = this.updateWindowDimensions.bind(this);
		this.pageScroll                 = this.pageScroll.bind(this);
		this.blockOffsets               = this.blockOffsets.bind(this);
	}
	
	handleChangeBefore = (e) => {
		let currentSlide = e.from,
		    nextSlide    = e.to,
		    sectionItem  = document.querySelectorAll('.section-item');
		
		
		this.setState({
			currentPage    : e.to,
		})
		if(this.state.modeScroll === "full-page") {
			if(currentSlide < nextSlide) {
				sectionItem[currentSlide].classList.add('section-item-active-next');
			} else {
				if(currentSlide > nextSlide) {
					sectionItem[currentSlide].classList.add('section-item-active-prev');
				}
			}
			window.location.hash = `section-${e.to}`
		}
	}
	
	handleChangeAfter = (e) => {
		if(this.state.modeScroll === "full-page") {
			let sectionItemsActive = document.querySelectorAll('.section-item-active-next, .section-item-active-prev');
			
			for(let items of sectionItemsActive) {
				items.classList.remove('section-item-active-next', "section-item-active-prev")
			}
		}
		
	};
	
	changeSlide(number) {
		if(this.state.width > 1199){
			this.fullPage.current.scrollToSlide(number);
		}else{
			window.scrollTo({
				top      : this.state.blockOffsets[number],
				behavior : "smooth"
			});
		}
		
	}
	
	changeStructureItem(number) {
		this.setState({
			structureItemActive : number
		})
	}
	
	handleChangeSlide(e) {
		let number = Number.parseInt(e.target.getAttribute('data-slide'));
		this.changeSlide(number)
	}
	
	handleChangeSlideStructure(e) {
		let number = Number.parseInt(e.target.getAttribute('data-slide'));
		this.changeSlide(number);
		let activeStructure = Number.parseInt(e.target.getAttribute('data-item'));
		this.changeStructureItem(activeStructure)
	}
	
	
	blockOffsets() {
		let sectionOffset = [];
		this.wrapper.current.querySelectorAll('.section-item').forEach((item, index) => {
			sectionOffset.push(item.offsetTop);
		})
		this.setState({
			blockOffsets : this.state.blockOffsets = sectionOffset
		});
	}
	
	updateWindowDimensions() {
		this.blockOffsets();
		this.setState({
			width : window.innerWidth,
		});
		
		
		if(this.state.width < 1200) {
			this.setState({
				modeScroll : 'normal'
			})
		} else {
			this.setState({
				modeScroll : 'full-page'
			})
		}
		if(this.state.modeScroll === 'full-page') {
			setTimeout(() => {
				this.changeSlide(this.state.currentPage)
			}, 500)
		}
		
	}
	
	pageScroll() {
		let scrollSeparate = window.scrollY + window.innerHeight / 2;
		this.state.blockOffsets.forEach((item, index) => {
			if(scrollSeparate >= item && scrollSeparate < this.state.blockOffsets[index + 1]) {
				if(this.state.currentPage !== index) {
					this.setState({
						currentPage : index
					})
				}
			}
		})
		
		
		if(scrollSeparate >= this.state.blockOffsets[this.state.blockOffsets.length - 1]) {
			if(this.state.currentPage !== this.state.blockOffsets.length - 1) {
				this.setState({
					currentPage : this.state.blockOffsets.length - 1
				})
			}
		}
	}
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		if(this.state.modeScroll === 'normal') {
			window.addEventListener('scroll', this.pageScroll);
		}
		
		if(this.props.location.hash !== '#section-0') {
			this.scrollUp.current.classList.add('active')
		} else {
			this.scrollUp.current.classList.remove('active')
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		let sectionIndex = Number.parseFloat(this.props.location.hash.replace(/#section-/g, ''));
		
		if(prevProps.location.hash !== this.props.location.hash && this.state.width > 767) {
			this.changeSlide(sectionIndex)
			if(this.props.location.hash !== '#section-0') {
				this.scrollUp.current.classList.add('active')
			} else {
				this.scrollUp.current.classList.remove('active')
			}
		} else {
			if(prevProps.location.hash !== this.props.location.hash && this.state.width <= 767) {
				if(this.state.currentPage !== sectionIndex) {
					this.setState({
						currentPage : sectionIndex
					})
				}
				window.scrollTo({
					top      : this.state.blockOffsets[sectionIndex],
					behavior : "smooth"
				});
			}
		}
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		if(this.state.modeScroll === 'normal') {
			window.removeEventListener('scroll', this.pageScroll);
		}
	}
	
	
	render() {
		let pagesOnHome = window.$GLOBAL.pagesOnHome;
		return (
			<div ref={this.wrapper}>
				
				
				<FullPage
					duration={1500}
					beforeChange={this.handleChangeBefore}
					afterChange={this.handleChangeAfter}
					ref={this.fullPage}
					initialSlide={this.state.width > 767 ? this.state.initialSlide : 0}
					scrollMode={this.state.modeScroll}
					className={"asdasdas"}
				>
					{
						pagesOnHome.map((pagesItem, index) => {
							
							pagesItem.animate                    = this.state.currentPage === index ? true : false;
							pagesItem.indexSection               = index;
							pagesItem.handleChangeSlideStructure = this.handleChangeSlideStructure;
							pagesItem.currentPage                = this.state.currentPage;
							pagesItem.handleChangeSlide          = this.handleChangeSlide;
							pagesItem.changeStructureItem        = this.changeStructureItem;
							pagesItem.structureActive            = this.state.structureItemActive;
							pagesItem.widthPage                  = this.state.width;
							return (
								<Slide
									className={"section-item"} key={`slide_${pagesItem.id}_${index}`}
								>
									{Components(pagesItem)}
								</Slide>
							)
							
							
						})
					}
				
				</FullPage>
				<NavLink to={`${window.$LNG}#section-0`} className="scrollUp" ref={this.scrollUp}>
					<i className="ic-arrow-up-light"></i>
				</NavLink>
			</div>
		)
	}
}

export default HomePage
