import React, {useRef} from "react";
import classes from "./VideoScreen.module.scss"

function VideoScreen(props) {
	const videoParentRef = useRef();
//	setTimeout(() => {
//		console.log(videoParentRef.current.children[0])
//		if(videoParentRef.current.children[0]) {
//			const player = videoParentRef.current.children[0];
//
//			if(player) {
//				player.controls    = false;
//				player.playsinline = true;
//				player.autoplay    = true;
//				player.play();
//
//			}
//		}
//
//	}, 0)
	
	return (
		<div className={classes.VideoScreen} ref={videoParentRef}>
			<video loop
			       autoPlay
			       muted
			       playsInline
			       style={{"backgroundImage" : `url(${props.poster})`}}
			       preload="none"
			
			>
				<source src={props.mp4}
				        type="video/mp4"/>
				<source src={props.ogv}
				        type="video/ogg; codecs=&quot;theora, vorbis&quot;"/>
				<source src={props.webm}
				        type="video/webm"/>
			
			</video>
		</div>
	)
}

export default VideoScreen