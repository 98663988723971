import React, {Component} from "react";
import classes from "./StructureInfo.module.scss";
import InfoItem from "./InfoItem/InfoItem";
import "./StructureInfoTransition.scss"


import {
	CSSTransition,
	SwitchTransition
} from "react-transition-group";



class StructureInfo extends Component {
	constructor(props) {
		super(props);
		this.items = Array.from({length : this.props.block.children.length}, () => React.createRef());
		this.icons = Array.from({length : this.props.block.children.length}, () => React.createRef());
		this.state = {
			selectItem : this.props.structureActive,
		}
	};
	
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.structureActive !== this.state.selectItem) {
			this.setState({
				selectItem : prevProps.structureActive,
			})
		}
	}
	
	render() {
		const block = this.props.block;
		const structureItems = block.children;
		return (
			<div className={classes.structureInfo}>
				<div className={classes.infoTab}>
					{structureItems.map(
						(item, index) => {
							return (
								<div className={`${classes.tabItem} ${this.state.selectItem === index ? classes.active : null}`}
								     key={`tab_${item.id}`}
								     data-item={index}
								     onClick={() => this.props.changeStructureItem(index)}
								     ref={this.items[index]}>
									<div className={classes.line}></div>
									<div className={classes.iconWrapper}>
										<div className={classes.icon} ref={this.icons[index]} dangerouslySetInnerHTML={{__html : item.icon}}>
										
										</div>
										<div className={classes.iconBorder} dangerouslySetInnerHTML={{__html : item.border}}>
										
										</div>
										<div className={classes.iconBorderActive} dangerouslySetInnerHTML={{__html : item.borderActive}}>
										
										</div>
									</div>
									<div className={classes.infoTitle} dangerouslySetInnerHTML={{__html : item.title}}>
									</div>
								</div>
							)
						})
					}
				</div>
				<SwitchTransition mode={'in-out'}>
					<CSSTransition
						key={this.state.selectItem}
						addEndListener={(node, done) => {
							node.addEventListener("transitionend", done, false);
						}}
						classNames="InfoItemTransition"
					>
						<InfoItem items={structureItems}
						          selectItem={this.state.selectItem}
						          handleChangeSlide={this.props.handleChangeSlide}
						          handleChangeItem={this.props.changeStructureItem}
						          currentPage={this.props.currentPage}
						          sectionIndex={this.props.block.indexSection}
						          widthPage={this.props.widthPage}
						/>
					
					</CSSTransition>
				</SwitchTransition>
			</div>
		
		)
	}
}

export default StructureInfo