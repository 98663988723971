import React, {Component} from "react";
import classes from "./HomeScreen.module.scss"
import VideoScreen from "../VideoScreen/VideoScreen";
import UtpSlider from "./UtpSlider/UtpSlider";


class HomeScreen extends Component {
	render() {
		const block         = this.props.block;
		const utpItems      = block.utp.items.slice().splice(1);
		const firstBlock = block.utp.items.slice()[0];
		const videoPoster   = block.videos.desktop.image,
		      videoMp4      = block.videos.desktop.file_mp4,
		      videoOgv      = block.videos.desktop.file_ogv,
		      videoWebm     = block.videos.desktop.file_webm,
		      videoPoster_m = block.videos.mobile.image,
		      videoMp4_m    = block.videos.mobile.file_mp4,
		      videoOgv_m    = block.videos.mobile.file_ogv,
		      videoWebm_m   = block.videos.mobile.file_webm;
		let video;
		if(this.props.widthPage <= 565) {
			video = <VideoScreen poster={videoPoster_m} mp4={videoMp4_m} ogv={videoOgv_m} webm={videoWebm_m}/>
		} else {
			video = <VideoScreen poster={videoPoster} mp4={videoMp4} ogv={videoOgv} webm={videoWebm}/>
		}
		return (
			<div className={classes.HomeScreen}>
				{video}
				<UtpSlider items={utpItems} firstBlock={firstBlock} animateTime="10000" widthPage={this.props.widthPage}/>
				<span className={classes.ScrollIcon}>
					<span className={classes.ScrollIconDot}>
						<span className={classes.ScrollIconDotInner}></span>
					</span>
				</span>
			</div>
		)
	}
}

export default HomeScreen;