import React, {Component} from "react";
import classes from "./IssuerYearSlider.module.scss";
import "./IssuerYearSlick.scss";
import Slider from "react-slick";

function SampleNextArrow(props) {
	const {onClick} = props;
	return (
		<div
			className={classes.nextButton}
			onClick={onClick}
		>
			<i className="ic-arrow-right-light"></i>
		</div>
	);
}

function SamplePrevArrow(props) {
	const {onClick} = props;
	return (
		<div
			className={classes.prevButton}
			onClick={onClick}
		>
			<i className="ic-arrow-left-light"></i>
		</div>
	);
}

class IssuerYearSlider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			yearsLength: this.props.issuerData.length
		}
	}

	render() {
		let {issuerData, handleChangeCurrentElement} = this.props;

		const settings = {
			slidesToShow: 8,
			slidesToScroll: 1,
			className: `${classes.IssuerYearSlider} IssuerYearSlider`,
			speed: 500,
			dots: false,
			autoplay: false,
			swipeToSlide: true,
			arrows: true,
			infinite: issuerData.length,
			prevArrow: <SamplePrevArrow/>,
			nextArrow: <SampleNextArrow/>,
			beforeChange: (oldIndex, newIndex) => {
				handleChangeCurrentElement(newIndex);
			},
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: Math.min(issuerData.length, 4),
					}
				}
			]
		};

		return (
			<Slider ref={c => (this.slider = c)} {...settings}>
				{
					issuerData.map((item, index) => {
						return (
							<div className={classes.issuer__yearItemWrapper}
							     key={index}
							     onClick={() => {this.slider.slickGoTo(index)}}>
								<div className={`${classes.issuer__yearItem} ${this.props.currentElement === index ? classes.issuer__yearItemActive : ''}`}>{item}</div>
							</div>
						)
					})
				}
			</Slider>
		)
	}
}

export default IssuerYearSlider;