import React, {Component} from "react";
import style from './Career.module.scss';
import CustomScroll from "react-custom-scroll";

export default class Career extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef()
        this.state = {
            vacancyData: [],
        }
    }
    
    animateBlock() {
        this.wrapper.current.classList.add(style.animateClass)
    }
    
    componentDidMount() {
        console.log(window.$LNG)
        if(window.$LNG !== '/en/'){
            const script = document.createElement("script");

            script.src = "https://www.work.ua/export/company/get_jobs_list.js?v=2";
            script.async = true;
            document.body.appendChild(script);
        }
    }
    
    componentDidUpdate() {
        if(this.props.animate) {
            this.animateBlock()
        }
    }

    careerVacancyList = () => {
        return this.state.vacancyData.map((elem, index) => {
            return(
                <li className={style.CareerVacancyItem}>
                    <div className={style.CareerVacancyItemHeader}>
                        <div className={style.CareerVacancyItemText}>{elem.name}</div>
                        {elem.salary ? <div className={style.CareerVacancyItemSalary} dangerouslySetInnerHTML={{__html:`${elem.salary} грн`}}></div>: ''}
                    </div>
                    <div className={style.CareerVacancyItemFooter}>
                        <div className={style.CareerVacancyItemRegion}><i className='ic-location-1'></i>{elem.region}</div>
                        <a href={elem.link} className={style.CareerVacancyItemLink} target="_blank" rel="noopener noreferrer">Подробнее <i className='ic-arrow-right-light'></i></a>
                    </div>
                </li>
            )
        });
    }


    render() {
        const CareerContent = this.props.block;
        let workUALanguage = ''
        if(window.$LNG === '/ua/'){
            workUALanguage = 'ua'
        }

        if(window.$LNG === '/'){
            workUALanguage = 'ru'
        }
    
        let back = '';
        if(this.props.widthPage >= 768 || window.innerWidth >= 768){
            back = CareerContent.image
        }else{
            back = CareerContent.imageForMobile !== '' ? CareerContent.imageForMobile : CareerContent.image
        }

        return(
            <section className={`container ${style.Career}`} style={{"backgroundImage": `url(${back})`}} ref={this.wrapper}>
                <div className={style.CareerContent}>
                    <div className={style.CareerHeadline}>{CareerContent.title}</div>
                    <div className={style.CareerContainer}>
                        <div className={style.CareerDescription}>
                            <div className={`text-formatted ${style.CareerDescriptionText}`} dangerouslySetInnerHTML={{__html : CareerContent.content}}>
                            </div>
                            <div className={style.CareerDescriptionHeadline}>
                                {CareerContent.blockLeftTitle}
                            </div>
                            <div className={`text-formatted ${style.CareerDescriptionList}`} dangerouslySetInnerHTML={{__html : CareerContent.blockLeftInformation}}></div>
    
                            <div className={style.CareerDescriptionPhoneWrapper}>
                                <div className={style.CareerDescriptionPhoneHeadline}>{CareerContent.blockLeftPhoneTitle}</div>

                                <a className={style.CareerDescriptionPhoneNumber} href={`tel:${CareerContent.blockLeftPhone.replace(/\s|\(|\)/gi,'')}`}>
                                    <i className='ic-cellphone-filled'></i>
                                    <span>{CareerContent.blockLeftPhone}</span>
                                </a>
                            </div>
                        </div>

                        <div className={style.CareerVacancy}>
                            <h2 className={style.CareerVacancyHeadline}>{CareerContent.blockRightTitle}</h2>

                            <div className={style.CareerVacancyListWrapper}>
                                <CustomScroll heightRelativeToParent="100%">
                                    <ul  id="work-jobs-list" data-id="230024" data-lang={workUALanguage}>
                                        {this.careerVacancyList()}
                                    </ul>
                                </CustomScroll>
                                {window.$LNG !== '/en/' && (
                                    <p className={style.CareerVacancyLink}>
                                        <a href="https://www.work.ua/jobs/by-company/230024/#open-jobs" target="_blank">{window.$GLOBAL.translation.linkAllJobsTitle}</a> на <a href="https://www.work.ua/" target="_blank">Work.ua</a>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}