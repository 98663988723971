import React, {Component} from "react";
import MenuToggle from "../UI/MenuToggle/MenuToggle";
import "./Header.scss"
import MainMenu from "../UI/MainMenu/MainMenu";
import Language from "../UI/Language/Language";
import {NavHashLink as NavLink} from 'react-router-hash-link';

class Header extends Component {
	constructor(props) {
		super(props);
		this.toggleMenuHandler      = this.toggleMenuHandler.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		
		this.state = {
			menuOpen : false,
			width    : window.innerWidth,
		}
	}
	
	toggleMenuHandler = () => {
		this.setState({
			menuOpen : !this.state.menuOpen
		});
		
	};
	
	updateWindowDimensions() {
		this.setState({
			width : window.innerWidth,
		});
	}
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.menuOpen) {
			this.props.pageContent.current.classList.add('active')
		} else {
			this.props.pageContent.current.classList.remove('active')
		}
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	render() {
		return (
			<React.Fragment>
				<MainMenu menuOpen={this.state.menuOpen}
				          toggleMenuHandler={this.toggleMenuHandler}
				          pageWidth={this.state.width}/>
				<header id="pageHeader" className={`container ${this.state.menuOpen ? 'menu-open' : ''}`}>
					<MenuToggle
						onToggleMenu={this.toggleMenuHandler}
						isOpenMenu={this.state.menuOpen}
					/>
					<div className="page-logo-wrapper">
						<NavLink to={`${window.$LNG}#section-0`}
						>
							
							{
								this.state.width >= 1200 ?
									<img src={window.$GLOBAL.logo} className="page-logo _desktop" alt={'apk-invest'}/>
									:
									<img src={window.$GLOBAL.logoMobile} alt="" className="page-logo _mobile"/>
							}
						
						
						</NavLink>
					</div>
					<Language/>
				</header>
				<div className={`content-overlay ${this.state.menuOpen ? 'menu-open' : 'active'}`}
				     onClick={this.toggleMenuHandler}></div>
			</React.Fragment>
		
		)
	}
}

export default Header;