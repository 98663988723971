import React from 'react';
import style from './IssuerTable.module.scss';
import CustomScroll from 'react-custom-scroll';
import "react-custom-scroll/dist/reactCustomScroll.js"
import "react-custom-scroll/dist/customScroll.css"
import "../../../customScroll.scss"

const IssuerTable = ({issuerData, currentElement}) => {
	const list = () => {
		return (
			issuerData[currentElement]?.reports.map((element, index) => {
				return (
					<li className={style.issuer__tableItem} key={`issuer_item_${index}`}>
						<span className={style.issuer__tableItemText}>{element.name}</span>
						<div className={style.issuer__tableItemTextLinks}>
							{element.link !== '' &&
							<a className={style.issuer__tableItemTextLink} href={element.link} target="_blank" rel="noopener noreferrer">
								<i className="ic-pdf-2"></i>
							</a>
							}
							
							{element.download !== '' &&
							<a className={style.issuer__tableItemTextLink} href={element.download} download target="_blank" rel="noopener noreferrer">
								<i className="ic-download-1"></i>
							</a>
							}
						
						</div>
					</li>
				)
			})
		)
	}
	
	return (
		<div className={style.issuer__table}>
			<div className={style.issuer__tableWrapper}>
				<CustomScroll heightRelativeToParent="100%">
					<ul className={style.issuer__tableList}>
						{list()}
					</ul>
				</CustomScroll>
			</div>
		</div>
	);
}

export default IssuerTable;