import React from "react";
import classes from "./InfoItem.module.scss"
import BrandVideo from "../../Widgets/BrandVideo/BrandVideo";
import BrandSlider from "../../Widgets/BrandSlider/BrandSlider";
import CustomScroll from "react-custom-scroll";

function InfoItem(props) {
	let infoItem = props.items[props.selectItem];
	let videoMp4, videoOgv, videoWebm, videoPoster;
	let back = '';
	if(props.widthPage >= 768) {
		videoMp4 = infoItem.videos.desktop.file_mp4;
		videoOgv = infoItem.videos.desktop.file_ogv;
		videoWebm = infoItem.videos.desktop.file_webm;
		videoPoster = infoItem.videos.desktop.poster;
		
		back = infoItem.image
	} else {
		videoMp4 = infoItem.videos.mobile.file_mp4;
		videoOgv = infoItem.videos.mobile.file_ogv;
		videoWebm = infoItem.videos.mobile.file_webm;
		videoPoster = infoItem.videos.mobile.poster;
		
		back = infoItem.imageForMobile !== '' ? infoItem.imageForMobile : infoItem.image
	}
	
	const navPrev = props.selectItem > 0 ?
		<div className={`${classes.infoNav} ${classes.infoPrev}`}
		     onClick={() => props.handleChangeItem(props.selectItem - 1)}>
			<i className={`ic ic-arrow-left-light ${classes.icon}`}></i>
			<div className={classes.number}>{props.selectItem}</div>
			{props.items[props.selectItem - 1].title.replace(/<br\/>/gi, '')}
		</div>
		:
		<div className={`${classes.infoNav} ${classes.infoPrev}`}
		     data-slide={props.sectionIndex - 1}
		     onClick={props.handleChangeSlide}
		>
			<i className={`ic ic-arrow-left-light ${classes.icon}`}></i>
			{window.$GLOBAL.pageTitles[props.sectionIndex - 1]}
		</div>;
	const navNext = props.selectItem < props.items.length - 1 ?
		<div className={`${classes.infoNav} ${classes.infoNext}`}
		     onClick={() => props.handleChangeItem(props.selectItem + 1)}
		>
			<div className={classes.number}>{props.selectItem + 2}</div>
			{props.items[props.selectItem + 1].title.replace(/<br\/>/gi, '')}
			<i className={`ic ic-arrow-right-light ${classes.icon}`}></i>
		</div>
		:
		<div className={`${classes.infoNav} ${classes.infoNext}`}
		     data-slide={props.sectionIndex + 1}
		     onClick={props.handleChangeSlide}
		>
			{window.$GLOBAL.pageTitles[props.sectionIndex + 1]}
			<i className={`ic ic-arrow-right-light ${classes.icon}`}></i>
		</div>;
	return (
		<React.Fragment>
			<div className={`container ${classes.infoList}`}
			     style={{"backgroundImage" : `url(${back})`}}
			>
				<div className={`${classes.infoBlock} ${props.selectItem % 2 ? classes._right : classes._left}`}>
					<div className={classes.blockText}>
						<div className={classes.blockTitle} dangerouslySetInnerHTML={{__html : infoItem.title}}>
						
						</div>
						<CustomScroll flex={"1"}>
							<div className={`text-formatted ${classes.blockContent}`}
							     dangerouslySetInnerHTML={{__html : infoItem.content}}></div>
						</CustomScroll>
					</div>
					<div className={classes.blockGallery}>
						
						{navPrev}
						
						{
							videoMp4 !== '' ?
								<BrandVideo mp4={videoMp4}
								            ogv={videoOgv}
								            webm={videoWebm}
								            poster={videoPoster}
								/>
								:
								<BrandSlider items={infoItem.gallery}/>
						}
						<div className={classes.mobileControl}>
							{navPrev}
							{navNext}
						</div>
						{navNext}
					</div>
				</div>
			</div>
		</React.Fragment>
	)
	
}

export default InfoItem;