import React, {Component} from "react";
import classes from "./UtpSlider.module.scss";
import CountUp from "react-countup";


class UtpSlider extends Component {
	constructor(props) {
		super(props);
		
		this.slider        = React.createRef();
		this.firstBlock    = React.createRef();
		this.items         = Array.from({length : this.props.items.length}, () => React.createRef());
		this.arrowPrev     = React.createRef();
		this.arrowNext     = React.createRef();
		this.timerFirst    = null;
		this.animateStart  = [];
		this.animateFinish = [];
		this.timerSlider   = null;
		
		this.state = {
			animateTime  : Number.parseInt(this.props.animateTime),
			currentSlide : Math.round(this.props.items.length / 2) * 2 - 2,
			slidePage    : Math.round(this.props.items.length / 2),
		};
		
		this.changeSlideNext = this.changeSlideNext.bind(this);
		this.changeSlidePrev = this.changeSlidePrev.bind(this);
		this.slideActive     = this.slideActive.bind(this);
		
	}
	
	
	changeSlideNext = () => {
		let slidePage   = this.state.slidePage * 2 - 2;
		let currentPage = this.state.currentSlide;
		console.log(slidePage);
		this.items.forEach((item, index) => {
			item.current.classList.remove(classes.visible)
			item.current.classList.remove(classes.out)
		})
		this.setState({
			currentSlide : currentPage >= slidePage ? 0 : currentPage + 2
		})
	}
	changeSlidePrev = () => {
		let slidePage   = this.state.slidePage * 2 - 2;
		let currentPage = this.state.currentSlide;
		
		this.items.forEach((item, index) => {
			item.current.classList.remove(classes.visible)
			item.current.classList.remove(classes.out)
		})
		this.setState({
			currentSlide : currentPage !== 0 ? currentPage - 2 : slidePage,
		})
	}
	
	slideActive() {
		this.items[this.state.currentSlide].current.classList.add(classes.visible);
		this.items[this.state.currentSlide + 1] &&
		this.items[this.state.currentSlide + 1].current.classList.add(classes.visible);
	}
	
	componentDidMount() {
		let animatedItems   = this.items;
		let animatePause    = 5000;
		let animateFinished = this.state.animateTime;
		
		this.timerFirst = setTimeout(() => {
			this.firstBlock.current.classList.add(classes.animate);
		}, 0)
		
		if(this.props.widthPage > 767) {
			animatedItems.forEach((item, index) => {
				this.animateStart[index] = setTimeout(function() {
					if(item.current) {
						item.current.classList.add(classes.animate)
					}
				}, animatePause);
				this.animateStart[index] = setTimeout(function() {
					if(item.current) {
						item.current.classList.add(classes.finished)
					}
				}, animateFinished + 1000);
				
				animatePause += 5000;
				
				animateFinished += 5000;
			})
			this.timerSlider = setTimeout(() => {
					for(let item of animatedItems) {
						item.current.classList.remove(classes.animate, classes.finished);
						item.current.classList.add(classes.sliderItem);
					}
					
					this.slideActive();
					
					this.arrowPrev.current.classList.add(classes.showArrow);
					this.arrowNext.current.classList.add(classes.showArrow);
				},
				5000 * this.props.items.length + 6500
			);
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		
		if(prevState.currentSlide !== this.state.currentSlide && this.props.widthPage > 767) {
			
			if(this.state.currentSlide !== 0) {
				this.items[this.state.currentSlide - 2].current.classList.add(classes.out);
				this.items[this.state.currentSlide - 1] &&
				this.items[this.state.currentSlide - 1].current.classList.add(classes.out);
				
			} else {
				this.items[this.props.items.length - 1].current.classList.add(classes.out);
				this.items[this.props.items.length - 2].current.classList.add(classes.out);
			}
			
			this.slideActive();
		}
	}
	
	componentWillUnmount() {
		clearTimeout(this.timerFirst);
		this.animateStart.forEach((item, index) => {
			clearTimeout(this.animateStart[index]);
			this.animateStart[index] = 0;
		});
		this.animateFinish.forEach((item, index) => {
			clearTimeout(item)
		});
		clearTimeout(this.timerSlider);
	}
	
	render() {
		
		const utpItems   = this.props.items;
		const firstBlock = this.props.firstBlock;
		let Delay        = 1;
		return (
			<div className={classes.sliderWrapper}>
				<div className={`${classes.first} ${classes.item}`} ref={this.firstBlock}>
					<div className={classes.numberWrapper}>
						<svg className={classes.circleHalf} viewBox="0 0 120 120">
							<circle r="59" cx="60" cy="60" fill="transparent"
							        strokeDasharray="1000" stroke="white" strokeWidth="1"></circle>
						</svg>
						<div className={classes.textUp}>
							{firstBlock.counter_prefix}
						</div>
						<div className={classes.number} dangerouslySetInnerHTML={{__html : firstBlock.content}}/>
						<div className={classes.textBottom}>
							{firstBlock.unit}
						</div>
					</div>
					<div className={classes.title}
					     dangerouslySetInnerHTML={{__html : firstBlock.content}}/>
				</div>
				<div className={classes.sliderArrow} onClick={this.changeSlidePrev} ref={this.arrowPrev}>
					<i className="ic-arrow-left-light"></i>
				</div>
				<div className={classes.sliderList} ref={this.slider}>
					{
						utpItems.map((item, index) => {
							let ItemNumber     = item.counter.replace(/\s/gi, '');
							let decimalsLength = ItemNumber.split('.');
							decimalsLength[1] ? decimalsLength = decimalsLength[1].length : decimalsLength = 0;
							Delay = Delay + 5;
							return (
								<div className={classes.item} key={index} ref={this.items[index]}>
									<div className={classes.numberWrapper}>
										<svg className={classes.circleHalf} viewBox="0 0 120 120">
											<circle r="59" cx="60" cy="60" fill="transparent"
											        strokeDasharray="1000" stroke="white" strokeWidth="1"></circle>
										</svg>
										<div className={classes.numberText}>
											{item.counter_prefix}
										</div>
										<CountUp className={classes.number}
										         start={0}
										         decimal=","
										         decimals={decimalsLength}
										         end={ItemNumber}
										         delay={Delay}
										         separator={" "}/>
										<div className={classes.numberText}>
											{item.unit}
										</div>
									</div>
									<div className={classes.title}
									     dangerouslySetInnerHTML={{__html : item.content}}/>
								</div>
							)
							
						})
					}
				
				</div>
				<div className={classes.sliderArrow} onClick={this.changeSlideNext} ref={this.arrowNext}>
					<i className="ic-arrow-right-light"></i>
				</div>
			</div>
		)
	}
}

export default UtpSlider