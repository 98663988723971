import React, {Component} from 'react';
import {
	SwitchTransition,
	CSSTransition
} from "react-transition-group";

import style from './IssuerContent.module.scss';
import IssuerYearSlider from "../IssuerYearList/IssuerYearSlider";
import IssuerTable from "../IssuerTable/IssuerTable";

import './issuerContentTransition.scss'


class IssuerContent extends Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
		this.state   = {
			currentElement : 0,
		}
	}
	
	handleChangeCurrentElement = (index) => {
		this.setState({
			currentElement : index,
		})
	}
	
	componentDidMount() {
		this.wrapper.current.classList.add(style.active)
	}
	
	render() {
		const {issuerData}     = this.props;
		const {currentElement} = this.state;
		
		return (
			<div className={style.issuer__content} ref={this.wrapper}>
				<IssuerYearSlider issuerData={issuerData.menuDocuments}
				                  currentElement={currentElement}
				                  handleChangeCurrentElement={this.handleChangeCurrentElement}/>
				
				<SwitchTransition mode={'out-in'}>
					<CSSTransition
						key={this.state.currentElement}
						addEndListener={(node, done) => {
							node.addEventListener("transitionend", done, false);
						}}
						classNames="issuerTransition"
					>
						<IssuerTable issuerData={issuerData.documents} currentElement={currentElement}/>
					
					</CSSTransition>
				</SwitchTransition>
			
			
			</div>
		);
	}
}

export default IssuerContent;