import React, {Component} from "react";
import classes from "./Preloader.module.scss"
import PreloaderIcon from "./PreloaderIcon";

export default class Preloader extends Component {
	constructor(props) {
		super(props);
		this.viewRef = React.createRef();
	}
	
	componentDidMount() {
		this.timer = setTimeout(() => {
			this.viewRef.current.classList.add(classes.animate)
		}, 5000)
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.hide) {
			setTimeout(() => {
				this.viewRef.current.classList.add(classes.hide)
			}, 500)
		}
		if(this.props.hide) {
			setTimeout(() => {
				this.viewRef.current.remove()
			}, 700)
		}
	}
	
	render() {
		let back = window.$GLOBAL.preloader.desktop;
		if (window.innerWidth >= 768){
			back = window.$GLOBAL.preloader.desktop;
		}else{
			back = window.$GLOBAL.preloader.mobile;
		}
		return (
			<div ref={this.viewRef} className={classes.Preloader} style={{"backgroundImage" : `url(${back})`}}>
				<div className={classes.PreloaderWrapper}>
					<PreloaderIcon/>
				</div>
			</div>
		);
	}
	
	componentWillUnmount() {
		this.viewRef.current.style.opacity = 0;
		clearTimeout(this.timer)
	}
}