import React, {Component} from 'react';
import style from './Partners.module.scss';
import PartnersItem from "./PartnersItem/PartnersItem";



class Partners extends Component {
    constructor(props) {
        super(props);
        this.wrapper =React.createRef();
    }
    
    animateBlock() {
        this.wrapper.current.classList.add(style.animateClass)
        
    }
    
    componentDidMount() {
        if(this.props.animate) {
            this.timer = setTimeout(() => {
                this.animateBlock()
            }, 300)
        }
    }
    
    componentDidUpdate() {
        if(this.props.animate) {
            this.animateBlock()
        }
    }
    
    componentWillUnmount() {
        clearTimeout(this.timer)
    }
    
    render() {
        const block  = this.props.block,
              parnersData = block.blocks;
    
        let back = '';
        if(this.props.widthPage >= 768 || window.innerWidth >= 768){
            back = block.image
        }else{
            back = block.imageForMobile !== '' ? block.imageForMobile : block.image
        }
        
        const list = () => {
            return(
                parnersData.map((element, index) => {
                    return(
                        <PartnersItem element={element} index={index} key={`partnerItem_${index}`}/>
                    )
                })
            )
        }
        return (
            <section className={`container ${style.partners}`} style={{"backgroundImage": `url(${back})`}} ref={this.wrapper}>
                <div className={style.partners__container}>
                    <h2 className={style.partners__headline}>{block.title}</h2>

                    <ul className={style.partners__list}>
                        {list()}
                    </ul>
                </div>
            </section>
        );
    }
}

export default Partners;