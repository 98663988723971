import React, {Component} from "react";
import classes from "./BrandsBigTitle.module.scss"


class BrandsBigTitle extends Component {
	constructor(props) {
	    super(props);
	    this.wrapper = React.createRef();
	}
    
    animateBlock () {
        this.wrapper.current.classList.add(classes.finished);
    }
    
    componentDidMount() {
        if (this.props.brandAnimate) {
            setTimeout(()=>{
                this.animateBlock()
            },300)
        }
    }
    
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.brandAnimate === true) {
			this.animateBlock()
		}
	}
	
	render() {
		let brandstitle = this.props.brandstitle;
		return (
			<div className={classes.bigTitle} ref={this.wrapper}>{brandstitle}</div>
		)
	}
}

export default BrandsBigTitle;