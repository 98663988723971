import React from "react";
import {NavHashLink as NavLink} from 'react-router-hash-link';
import classes from "./MenuList.module.scss"

const SubMenu = (props) => (
  <ul className={classes.submenu}>
    {
			props.item.menuSecondLvl.map((subItem) => (
				<li key={subItem.id}>
					<NavLink
						to={window.$LNG + subItem.clearUrl}
						className={classes.link}
						scroll={(el) => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
						onClick={props.pageWidth < 768 ? props.onToggleMenuLink : null}
					>
						<span>{subItem.link_text}</span>
					</NavLink>
				</li>
			))
		}
  </ul>
);


const MenuList = props => {
	if(props.menuBlock) {
		let menuItems = props.menuBlock;
		return (
			<ul className={classes.MenuList}>
				{
					menuItems.map((item, index) => {
						return (
							<li className={classes.item} key={`menu_${index}`}>
								{
									props.innerPages ?
									(
										<>
											<NavLink to={`${window.$LNG}#section-${index}`}
															className={classes.link}
															scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
															onClick={props.pageWidth < 768 ? props.onToogleMenuLink : null}
											>
												<span>{item.link_text}</span>
											</NavLink>
											{
												Boolean(item.menuSecondLvl?.length) && <SubMenu {...props} item={item} />
											}
										</>
									) : (
										<>
											<NavLink to={window.$LNG + item.clearUrl}
															className={`${classes.link} ${classes.linkMenu} ${window.$LNG === '/en/' && classes._c_hide} ${item.clearUrl === 'career' && classes._hide}`}
															activeClassName={classes.active}
															onClick={props.pageWidth < 768 ? props.onToogleMenuLink : null}
											>
												<span>{item.link_text}</span>
											</NavLink>
											{
												Boolean(item.menuSecondLvl?.length) && <SubMenu {...props} item={item} />
											}
										</>
									)
								}
							</li>
						)
					})
				}
			</ul>
		)
	} else {
		return (
			<div></div>
		)
	}
}

export default MenuList