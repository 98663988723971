import React, {Component} from "react";
import {Formik} from 'formik';
import "../../Widgets/FormElements/Inputs/Input.scss"
import Input from "../../Widgets/FormElements/Inputs/Input";
import classes from "./TourForms.module.scss";


class TourForms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formMessage: ''
		}
		this.timer =setTimeout(()=>{});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.formMessage !== this.state.formMessage){
			this.timer = setTimeout(()=>{
				this.setState({
					formMessage: ''
				})
			},4000)
		}
	}
	
	componentWillUnmount() {
		clearTimeout(this.timer);
	}
	
	render() {
		return (
			
			
			<Formik
				initialValues={{
					email    : '',
					password : '',
				}}
				validate={values => {
					const errors = {};
					if (!values.email) {
						errors.email = '';
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
					) {
						errors.email = 'Invalid email address';
					}
					return errors;
				}}
				onSubmit={(values, {setSubmitting, resetForm}) => {
					setTimeout(() => {
						setSubmitting(false);
						fetch(window.$GLOBAL.authorizationLink, {
							method  : "POST",
							headers : {
								'Content-Type' : 'application/x-www-form-urlencoded'
							},
							body    : JSON.stringify(values, null, 2),
							cache   : "no-cache",
							
						}).then(response => {
							
							response.json().then(data => {
							
								if(data.success_msg) {
									window.open(this.props.tourText.link);
									resetForm(values = '')
								}
								
								if(data.error){
									this.setState({
										formMessage: data.error
									})
								}
							});
							
						})
					}, 400);
					
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit} className={classes.TourForms} id={this.props.id}>
						{
							this.state.formMessage !== '' &&
							<div className="form-error">
								{this.state.formMessage}
							</div>
						}
						
						<Input
							inputtype={"email"}
							title={window.$GLOBAL.translation.inputEmailLabel}
							iconname={"ic-name-1"}
							name={"email"}
							value={values.email}
							placeholder=""
							onChange={handleChange}
							errortext={errors.email}
						/>
						<Input
							inputtype={"password"}
							title={window.$GLOBAL.translation.inputPasswordLabel}
							iconname={"ic-password"}
							name={"password"}
							value={values.password}
							placeholder=""
							onChange={handleChange}
						/>
						<div className={classes.TourButtonWrapper}>
							<button type="submit" disabled={isSubmitting} className={"btn btn-transperent btn-blurry"}>
								{this.props.tourText.titleLink}
							</button>
						</div>
					</form>
				)}
			
			</Formik>
		)
	}
}

export default TourForms;