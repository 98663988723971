import React, {Component} from "react";
import ReactDOM from "react-dom";
import style from './HistoryPage.module.scss';
import HistoryYearsList from "./HistoryYearsList/HistoryYearsList";

export default class HistoryPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			brandAnimate : false
		};
	}
	
	componentDidUpdate() {
		if(this.props.animate) {
			ReactDOM.findDOMNode(this).classList.add(style.animateClass)
		}
	}
	
	render() {
		const {animate} = this.props;
		const block     = this.props.block,
		      yearList  = block.children;
		
		let back = '';
		if(this.props.widthPage >= 768 || window.innerWidth >= 768){
			back = block.image
		} else {
			back = block.imageForMobile !== '' ? block.imageForMobile : block.image
		}
		
		return (
			<section className={`container ${style.HistoryPage}`} style={{"backgroundImage" : `url(${back})`}}>
				<div className={style.HistoryContent}>
					<h1 className={style.HistoryContentHeadline}>{block.title}</h1>
					<HistoryYearsList yearList={yearList} animate={animate}/>
				</div>
			</section>
		);
	}
}