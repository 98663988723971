import React from 'react';
import style from "./PartnersItem.module.scss";

const PartnersItem = ({element, index}) => {

    return (
        <li className={style.partners__item}>
            <img className={style.partners__itemImage} src={element.blockImage} alt={element.blockTitle}/>
            <div className={style.partners__itemHeadline}>{element.blockTitle}</div>
            <div className={style.partners__itemText}>{element.blockInformation}</div>

            <a href={element.blockLink} rel={"noopener noreferrer"} target="_blank" className="btn btn-blurry">
                <span className="btn-text-default">{element.blockLinkTitle}</span>
                <span className="btn-text-hover">{element.blockLinkTitle}</span>
            </a>
        </li>
    );
}

export default PartnersItem;
