import React, {Component} from 'react';
import './reset.scss';
import './fonts/fontello/css/fontello.css'
import './index.scss';
import Header from "./component/Header/Header";
import HomePage from "./component/HomePage/HomePage";
import {
	Route,
	Switch,
	withRouter,
	
} from "react-router-dom";

import TransitionGroup from "react-transition-group/cjs/TransitionGroup";
import CSSTransition from "react-transition-group/cjs/CSSTransition";
import Preloader from "./component/Widgets/Preloader/Preloader";
import componentRoute from "./componentRoute";
import turnImage from "./images/turn.jpg"

function demoAsyncCall() {
	return new Promise((resolve) => setTimeout(() => resolve(), 5000));
}


class App extends Component {
	constructor(props) {
		super(props);
		this.pageContent = React.createRef()
		this.state       = {
			loading : true,
			pages : window.$GLOBAL.pages,
		};
	}
	
	
	componentDidMount() {
		demoAsyncCall().then(() => this.setState({loading : false}));

		const newPages = [];

		window.$GLOBAL.pages.forEach(page => {
			if(page.children?.length ) {
				const pagesWithFullUrl = page.children.map(child => ({
					documents: [],
					...child,
					url_key: page.url_key + '/' + child.url_key,
				}));
				newPages.push(...pagesWithFullUrl);
			}
		});

	  this.setState(prev => ({
			...prev,
			pages: [...prev.pages, ...newPages]
		}))
	}
	
	
	
	render() {
		const {loading} = this.state;

		if(loading) {
			
			return (
				<Preloader />
			);
		} else {
			const AnimatedSwitch = withRouter(({location}) => (
				<TransitionGroup>
					<CSSTransition key={location.pathname} classNames="pageSlide" timeout={1000}>
						<Switch location={location}>
							<Route
								path={window.$LNG}
								exact component={HomePage}/>
								
							{this.state.pages.map((item,index) => {
								return (
									<Route path={window.$LNG + encodeURI(item.url_key)} exact component={() => componentRoute(item)} key={item.id}/>
								)
							})}
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			
			));
			return (
				<React.Fragment>
					<Preloader hide={true}/>
					<Header pageContent={this.pageContent}/>
					<div className={`page-content ${this.state.menuOpen ? 'active' : ''}`} ref={this.pageContent}>
						<AnimatedSwitch/>
					</div>
					<div className="orientation-block">
						<img src={turnImage} alt="turn phone"/>
					</div>
				</React.Fragment>
			);
			
		}
	}
}

export default App;
