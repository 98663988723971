import React, {Component} from "react";
import classes from "./CompanyStructure.module.scss"

class CompanyStructure extends Component {
	constructor(props) {
		super(props);
		this.items   = Array.from({length : this.props.block.children.length}, () => React.createRef());
		this.icons   = Array.from({length : this.props.block.children.length}, () => React.createRef());
		this.wrapper = React.createRef();
		this.state   = {
			hoverIndex : null,
		}
		
	}
	
	componentDidMount() {
		for(let icon of this.icons) {
			let iconElems = icon.current.querySelectorAll('path,polyline,line,rect,polygon,ellipse');
			for(let elem of iconElems) {
				let length                  = elem.getTotalLength();
				elem.style.strokeDasharray  = length;
				elem.style.strokeDashoffset = length;
			}
		}
		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		this.timerInner = []
		let index       = 0
		if(this.props.animate) {
			let structureItems = this.items,
			    timer          = 0;
			this.timer         = setTimeout(() => {
				for(let item of structureItems) {
					this.timerInner[index] = setTimeout(() => {
						item.current.classList.add(classes.animate)
					}, timer)
					index++;
					timer += 1500;
				}
				this.wrapper.current.classList.add(classes.structureAnimate)
			}, 1000)
			
		}
	}
	
	componentWillUnmount() {
		clearTimeout(this.timer);
		this.timerInner.map((item, index) => {
			return (
				clearTimeout(item)
			)
		})
	}
	
	
	render() {
		const block          = this.props.block;
		const structureItems = block.children;
		let back             = '';
		if(this.props.widthPage >= 768 || window.innerWidth >= 768){
			back = block.image
		} else {
			back = block.imageForMobile !== '' ? block.imageForMobile : block.image
		}
		return (
			<section className={`container ${classes.Structure}`}
			         style={{"backgroundImage" : `url(${back})`}}
			         ref={this.wrapper}
			>
				<div className={classes.structureTitle}>{block.title}</div>
				<div className={classes.structureList}>
					{structureItems.map(
						(item, index) => {
							return (
								<div className={classes.structureItem}
								     key={`sItem_${index}`}
								     data-slide={this.props.currentPage + 1}
								     data-item={index}
								     onClick={this.props.handleChangeSlideStructure}
								     ref={this.items[index]}>
									<div className={classes.line}/>
									<div className={classes.iconWrapper}>
										<div className={classes.icon}
										     ref={this.icons[index]}
										     id={`iconStructure_${item.id}`}
										     dangerouslySetInnerHTML={{__html : item.icon}}>
										
										</div>
										<div className={classes.iconBorder}
										     dangerouslySetInnerHTML={{__html : item.border}}/>
									</div>
									<div className={classes.itemTitle} dangerouslySetInnerHTML={{__html : item.title}}/>
								</div>
							)
						})
					}
				</div>
			</section>
		)
	}
}

export default CompanyStructure