import React, {Component} from 'react';
import style from "./HistoryContent.module.scss";
import ReactDOM from "react-dom";
import CustomScroll from "react-custom-scroll";

class HistoryContent extends Component {
	componentDidUpdate() {
		if(this.props.animate) {
			ReactDOM.findDOMNode(this).classList.add(style.animateClass)
		}
	}
	
	render() {
		const {yearList, selectItem} = this.props;
		
		return (
			<div className={style.HistoryItemContentWrapper}>
				<CustomScroll heightRelativeToParent="100%">
					<div className={`text-formatted ${style.HistoryItemContentText}`}
					     dangerouslySetInnerHTML={{__html : yearList[selectItem].content}} />
				</CustomScroll>
			</div>
		);
	};
};

export default HistoryContent;