import React from "react";
import classes from "./VideoBrand.module.scss"

function BrandVideo(props) {
	return (
		<video className={classes.BrandVideo}
		       loop
		       autoPlay
		       muted
		       playsInline
		       preload="none"
		       poster={props.poster ? props.poster : null}
		>
			<source src={props.mp4}
			        type="video/mp4"/>
			<source src={props.ogv}
			        type="video/ogg; codecs=&quot;theora, vorbis&quot;"/>
			<source src={props.webm}
			        type="video/webm"/>
		</video>
	)
}


export default BrandVideo