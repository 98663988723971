import React, {Component} from "react";
import CustomScroll from 'react-custom-scroll';
import "react-custom-scroll/dist/reactCustomScroll.js"
import "react-custom-scroll/dist/customScroll.css"
import style from './Certificate.module.scss';
import CertificateSlider from "./CertificateSlider/CertificateSlider";

import './certificateContentTransition.scss'

export default class Certificate extends Component {
	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
		this.state   = {
			currentItem : 0,
		}
	}
	
	animateBlock() {
		this.wrapper.current.classList.add(style.animateClass)
	}
	
	componentDidMount() {
		if(this.props.animate) {
			setTimeout(() => {
				this.animateBlock()
			}, 300)
		}
	}
	
	componentDidUpdate() {
		if(this.props.animate) {
			this.animateBlock()
		}
	}
	
	
	handleChangeItem = (index) => {
		this.setState({
			currentItem : index
		})
	}
	
	render() {
		const CertificateData = this.props.block;
		
		let back = '';
		if(this.props.widthPage >= 768 || window.innerWidth >= 768){
			back = CertificateData.image
		} else {
			back = CertificateData.imageForMobile !== '' ? CertificateData.imageForMobile : CertificateData.image
		}
		
		const CertificateIconList = () => {
			return CertificateData.children.map((element, index) => {
				return (
					<li onClick={() => this.handleChangeItem(index)}
					    className={`${style.CertificateIconItem} ${this.state.currentItem === index ? style.CertificateIconItemActive : ''}`}
					    key={element.id}>
						<img className={style.CertificateIconImage} src={element.logoCertificate} alt={element.title}/>
					</li>
				)
			})
		}
		
		const CertificateSliderItem = () => {
			return (
				<div className={style.CertificateSliderWrapper}>
					<CertificateSlider handleChangeItem={this.handleChangeItem}
					                   currentItem={this.state.currentItem}
					                   items={CertificateData}/>
				</div>
			)
		}
		
		return (
			<section className={`container ${style.Certificate}`}
			         style={{"backgroundImage" : `url(${back})`}}
			         ref={this.wrapper}>
				<div className={style.CertificateContent}>
					<div className={style.CertificateContentHeadline}>{CertificateData.title}</div>
					
					<div className={style.CertificateContentContainer}>
						<ul className={style.CertificateIconList}>
							{CertificateIconList()}
						</ul>
						<div className={style.CertificateTextContainer}>
							<div className={style.CertificateTextWrapper}>
								<div className="certificateTextWrapper">
									<CustomScroll heightRelativeToParent="98%" onScroll={this.scrolledHandle}>
										<div className={`text-formatted ${style.CertificateTextData}`} dangerouslySetInnerHTML={{__html : CertificateData.content}}>
										</div>
									</CustomScroll>
								</div>
							</div>
							{CertificateSliderItem()}
						</div>
					</div>
				</div>
			</section>
		);
	}
}