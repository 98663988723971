import React, {Component} from 'react';
import style from "./TeamItem.module.scss";
import "./TeamItemTransition.scss";

class TeamItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            biographyOpen: false,
        }
    }

    handleChange = () => {
        this.setState({
            biographyOpen: !this.state.biographyOpen
        })
    }

    render() {
        const {elem, index, block} = this.props;
        const {biographyOpen} = this.state;
        return (
            <li key={index} className={style.TeamListItem}>
                <div className={style.TeamListImageWrapperMain}>
                    <img className={style.TeamListImage} src={elem.image} alt={elem.fio}/>
                </div>
                <div className={style.TeamListItemText}>
                    <p className={style.TeamListItemName}>{elem.fio}</p>
                    <p className={style.TeamListItemPosition}>{elem.employee_position}</p>
                    <button onClick={this.handleChange} className={`${style.TeamListItemButton} ${biographyOpen ? style.TeamListItemButtonActive : ''}`}>
                        <span className={style.TeamListItemButtonText}>
                            {biographyOpen ? block.linkTitleToggle : block.linkTitle}
                        </span>
                        <i className='ic-arrow-right-light'></i>
                    </button>
                    <div className={`${style.TeamListItemContentText} ${biographyOpen ? style.TeamListItemContentTextActive : ''}`} dangerouslySetInnerHTML={{__html: elem.content}}></div>


                </div>
            </li>
        );
    }
}

export default TeamItem;