import React, {Component} from "react";
import classes from "./HistorySlider.module.scss";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const {onClick} = props;
    return (
        <div
            className={classes.nextButton}
            onClick={onClick}
        >
            <i className="ic-arrow-right-light"></i>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {onClick} = props;
    return (
        <div
            className={classes.prevButton}
            onClick={onClick}
        >
            <i className="ic-arrow-left-light"></i>
        </div>
    );
}

class HistorySlider extends Component {

    render() {
        const settings = {
            slidesToShow   : 1,
            slidesToScroll : 1,
            speed          : 1000,
            dots           : false,
            autoplay       : false,
            arrows         : true,
            adaptiveHeight: true,
            nextArrow      : <SampleNextArrow/>,
            prevArrow      : <SamplePrevArrow/>,
        };
        let utpItems   = this.props.items;


        return (
            <Slider ref={c => (this.slider = c)} {...settings}>
                {
                    utpItems.map((item, index) => {
                        return (
                            <div className={classes.item} key={index}>
                                <img src={item.image} alt=""/>
                            </div>
                        )

                    })
                }
            </Slider>
        )
    }
}

export default HistorySlider;